<template>
  <div class="main">
    <router-view v-slot="{ Component }">
      <component :is="Component" :key="$route.name" v-if="!$route.meta.keepAlive"/>
      <keep-alive>
        <component :is="Component" :key="$route.name" v-if="$route.meta.keepAlive"/>
      </keep-alive>
    </router-view>
  </div>
</template>

<script>
  

  export default {
    name: 'App',
    components: {
      
    },
    data(){
      return {
      }
    },
    mounted(){
    },
    methods: {
    }
  }
</script>

<style>
    .container{
      position: relative;
    }
    .container .ele{
      position: absolute;
    }
    canvas{
        position: absolute;
        top: 0;
    }
</style>
