<template>
    <div class="container" id="container" :style="{width:frameW+'px',height: frameH+'px', backgroundColor: backgroundColor}">
      <template
          v-for="item, index in templateList"
          :key="index">
          <span v-if="item.type == 'text'" :style="item.styleObj" :class="[item.class, item.fadeIn ? 'fade-in':'' , 'ele']" v-show="item.showElement">{{ item.text }}</span>
          <img v-if="item.type == 'img'" :src="item.src" class="ele" :style="item.styleObj" :class="[item.fadeIn ? 'fade-in':'' ]">
      </template>
      <template v-for="item, index in animationList" :key="index">
          <MovingStarrySky v-if="item.type == 'moving-starry-sky'"></MovingStarrySky>
          <HeartBubble v-if="item.type == 'heart-bubble'"></HeartBubble>
          <Meteor v-if="item.type == 'meteor'"></Meteor>
          <StarrySky v-if="item.type == 'starry-sky'"></StarrySky>
          <Snow v-if="item.type == 'snow'"></Snow>
      </template>
    </div>
  </template>
  
  <script>
    import request from '@/utils/request'
    import { Fireworks } from 'fireworks-js'
    import HeartBubble from '@/components/background/HeartBubble.vue';
      import MovingStarrySky from '@/components/background/MovingStarrySky.vue';
      import Meteor from '@/components/background/Meteor.vue';
      import Snow from '@/components/background/Snow.vue';
  
    export default {
      name: 'Index',
      components: {
        HeartBubble,
          MovingStarrySky,
          Meteor,
          Snow
      },
      data(){
        return {
          templateList: [],
          frameW: 400,
          frameH: 400,
          backgroundColor: '#fff',
          animationList: []
        }
      },
      mounted(){
        this.getList()
      },
      methods: {
        
        getList(){
         let uid = this.$route.params.uid
          request({
                method:'GET',
                url: '/v1/template/'+uid,
            }).then((res)=>{
              if(res.data.code == 200){
                  this.frameW = res.data.data.width
                  this.frameH = res.data.data.height
                  this.backgroundColor = res.data.data.backgroundColor
                  if(res.data.data.animation){
                    this.animationList = JSON.parse(res.data.data.animation)
                    console.log(this.animationList)
                    for(let i=0; i<this.animationList.length; i++){
                      switch(this.animationList[i].type){
                        case "fireworks":
                          this.fireworksAnimation(this.animationList[i].options)
                      }
                    }
                  }
                  let templateList = JSON.parse(res.data.data.content)
                  console.log(templateList)
                  
                  for(let i=0; i<templateList.length; i++){
                    templateList[i].styleObj = this.getStyle(templateList[i])
                    if(templateList[i].delay && templateList[i].delay > 0){
                      setTimeout(()=>{
                        this.templateList[i].showElement = true
                        console.log('delay')
                      }, templateList[i].delay)
                    }else{
                      templateList[i].showElement = true
                    }
                  }
                  this.templateList = templateList
              }
            })
        },
        getStyle(template){
              let style = {}
              for (let key in template.style) {
                  switch(key){
                      case "fontSize":
                      style[key] = `${template.style[key]}px`
                          break
                      default:
                      style[key] = `${template.style[key]}`
                  }
              }
              style.width = `${template.width}px`
              style.height = `${template.height}px`
              style.top = `${template.top}px`
              style.left = `${template.left}px`
              return style
        },
        fireworksAnimation(options){
          const container = document.querySelector('.container')
          const fireworks = new Fireworks(container, options)
          fireworks.start()
        }
      }
    }
  </script>
  
  <style>
      .container{
        position: relative;
      }
      .container .ele{
        position: absolute;
      }
      canvas{
          position: absolute;
          top: 0;
      }
  </style>
  