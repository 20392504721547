import {createRouter, createWebHistory } from "vue-router"

import Home from '../src/page/Index'

const router = new createRouter({
    routes:[
        {
            path:'/:uid',
            name: 'home',
            component: Home,
        },
    ],
    history: createWebHistory()
})
export default router;