/*引入axios*/
import axios from 'axios'

let baseURL = 'https://gif-api.xixuan.net'

//  baseURL = 'http://127.0.0.1:9696'

const request = axios.create({
    baseURL: baseURL, // 基础路径,将统一的部分全部封装
    contentType: 'application/json',
    withCredentials: false,
})

request.interceptors.request.use(function (config) {
    //一定要返回config   否则请求停在这里 出不去
    return config;
}, function (error) {
    // 对请求错误做些(还没有发出请求)
    console.log('request error', error)
    return Promise.reject(error);
});


export default request

