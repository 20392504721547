<template>
    <div class="animation-container">
        <div class="heart-bubble heart1"></div>
        <div class="heart-bubble heart2"></div>
        <div class="heart-bubble heart3"></div>
        <div class="heart-bubble heart4"></div>
        <div class="heart-bubble heart5"></div>
    </div>
</template>

<script>
    export default {
        name: 'SubScribe',
        props: {
        
        },
        data() {
        return{
            
        }
        },
        mounted() {
        
        },
        methods: {}
    }
</script>

<style scoped>
.bubble-container{
    width: 100%;
    height: 100%;
}
.heart-bubble {
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: #EF686E;
    transform: rotate(-45deg);
}

.heart-bubble:before {
    content: "";
    position: absolute;
    top: -25px;
    left: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #EF686E;
}

.heart-bubble:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 25px;
    width: 50px;
    height: 50px;
    background-color: #EF686E;
    border-radius: 50%;
}

@keyframes up {
    0% {
        bottom: 0px;
    }

    100% {
        bottom: 700px;
    }
}

@keyframes side {
    0% {
        margin-left: 0;
    }

    100% {
        margin-left: 150px;
    }
}

.heart1 {
    transform: rotate(300deg) scale(0.6);
    opacity: .7;
    animation: up 15s linear infinite, side 6s ease-in-out infinite alternate;
}
.heart2 {
    left: 150px;
    transform: rotate(-30deg) scale(0.5);
    opacity: .5;
    animation: up 15s linear infinite, side 5s ease-in-out infinite alternate;
}


.heart3 {
    left: 200px;
    transform: rotate(300deg) scale(0.5);
    opacity: .4;
    animation: up 15s linear infinite, side 4s ease-in-out infinite alternate;
}

.heart4 {
    left: 250px;
    transform: rotate(-25deg) scale(0.6);
    opacity: .7;
    animation: up 18s linear infinite, side 2s ease-in-out infinite alternate;
}


.heart5 {
    left: 350px;
    transform: rotate(-30deg) scale(0.6);
    opacity: .6;
    animation: up 20s linear infinite, side 3s ease-in-out infinite alternate;
}
</style>