<template>
    <div class="snow"> 
        <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
  <div class="snowflake"> <span> </span></div>
    </div>
</template>

<script>
    export default {
        name: 'SubScribe',
        props: {
        
        },
        data() {
        return{
            
        }
        },
        mounted() {
        
        },
        methods: {}
    }
</script>

<style scoped>
    @charset "UTF-8";
.snow {
  position: absolute;

}

.snowflake {
  position: absolute;
  top: -5vmin;
}
.snowflake:nth-child(1) {
  opacity: 0.56;
  font-size: 3px;
  left: 86.3vw;
  animation: fall-1 30s -12s ease-in infinite;
}
.snowflake:nth-child(1) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-1 {
  6% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 58.4vw;
  }
}
.snowflake:nth-child(2) {
  opacity: 0.41;
  font-size: 3px;
  left: 36.2vw;
  animation: fall-2 20s -22.5s ease-in infinite;
}
.snowflake:nth-child(2) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-2 {
  7.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 119.1vw;
  }
}
.snowflake:nth-child(3) {
  opacity: 0.09;
  font-size: 6px;
  left: 119.6vw;
  animation: fall-3 40s -19.5s ease-in infinite;
}
.snowflake:nth-child(3) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-3 {
  1.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 51.6vw;
  }
}
.snowflake:nth-child(4) {
  opacity: 0.3;
  font-size: 12px;
  left: 119.8vw;
  animation: fall-4 10s -22.5s ease-in infinite;
}
.snowflake:nth-child(4) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-4 {
  8.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 50vw;
  }
}
.snowflake:nth-child(5) {
  opacity: 0.12;
  font-size: 3px;
  left: 22.7vw;
  animation: fall-5 20s -9s ease-in infinite;
}
.snowflake:nth-child(5) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-5 {
  8% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 63.4vw;
  }
}
.snowflake:nth-child(6) {
  opacity: 0.38;
  font-size: 12px;
  left: 48.5vw;
  animation: fall-6 50s -7.5s ease-in infinite;
}
.snowflake:nth-child(6) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-6 {
  8.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 20.5vw;
  }
}
.snowflake:nth-child(7) {
  opacity: 0.06;
  font-size: 9px;
  left: 112.5vw;
  animation: fall-7 40s -12s ease-in infinite;
}
.snowflake:nth-child(7) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-7 {
  1.6666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 70.2vw;
  }
}
.snowflake:nth-child(8) {
  opacity: 0.17;
  font-size: 3px;
  left: 109.3vw;
  animation: fall-8 30s -9s ease-in infinite;
}
.snowflake:nth-child(8) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-8 {
  2.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 106.1vw;
  }
}
.snowflake:nth-child(9) {
  opacity: 0.02;
  font-size: 6px;
  left: 57.1vw;
  animation: fall-9 40s -24s ease-in infinite;
}
.snowflake:nth-child(9) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-9 {
  3.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 64.1vw;
  }
}
.snowflake:nth-child(10) {
  opacity: 0.17;
  font-size: 12px;
  left: 95.4vw;
  animation: fall-10 10s -7.5s ease-in infinite;
}
.snowflake:nth-child(10) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-10 {
  6.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 4.9vw;
  }
}
.snowflake:nth-child(11) {
  opacity: 0.81;
  font-size: 12px;
  left: 106.9vw;
  animation: fall-11 50s -6s ease-in infinite;
}
.snowflake:nth-child(11) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-11 {
  8.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 41.2vw;
  }
}
.snowflake:nth-child(12) {
  opacity: 0.34;
  font-size: 6px;
  left: 89.8vw;
  animation: fall-12 50s -22.5s ease-in infinite;
}
.snowflake:nth-child(12) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-12 {
  6.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 48vw;
  }
}
.snowflake:nth-child(13) {
  opacity: 0.81;
  font-size: 9px;
  left: 13.9vw;
  animation: fall-13 20s -4.5s ease-in infinite;
}
.snowflake:nth-child(13) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-13 {
  5.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 17.2vw;
  }
}
.snowflake:nth-child(14) {
  opacity: 0.45;
  font-size: 6px;
  left: 5.9vw;
  animation: fall-14 50s -37.5s ease-in infinite;
}
.snowflake:nth-child(14) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-14 {
  7% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 72vw;
  }
}
.snowflake:nth-child(15) {
  opacity: 0.2;
  font-size: 9px;
  left: 24.5vw;
  animation: fall-15 40s -7.5s ease-in infinite;
}
.snowflake:nth-child(15) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-15 {
  1.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 80vw;
  }
}
.snowflake:nth-child(16) {
  opacity: 0.21;
  font-size: 12px;
  left: 60.4vw;
  animation: fall-16 40s -22.5s ease-in infinite;
}
.snowflake:nth-child(16) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-16 {
  3% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 5.5vw;
  }
}
.snowflake:nth-child(17) {
  opacity: 0.61;
  font-size: 3px;
  left: 58.9vw;
  animation: fall-17 40s -22.5s ease-in infinite;
}
.snowflake:nth-child(17) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-17 {
  6.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 39.4vw;
  }
}
.snowflake:nth-child(18) {
  opacity: 0.11;
  font-size: 3px;
  left: 20.6vw;
  animation: fall-18 20s -16.5s ease-in infinite;
}
.snowflake:nth-child(18) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-18 {
  0.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 90.7vw;
  }
}
.snowflake:nth-child(19) {
  opacity: 0.18;
  font-size: 9px;
  left: 17.2vw;
  animation: fall-19 50s -18s ease-in infinite;
}
.snowflake:nth-child(19) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-19 {
  7% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 75.9vw;
  }
}
.snowflake:nth-child(20) {
  opacity: 0.28;
  font-size: 6px;
  left: 96.1vw;
  animation: fall-20 40s -3s ease-in infinite;
}
.snowflake:nth-child(20) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-20 {
  2.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 57.6vw;
  }
}
.snowflake:nth-child(21) {
  opacity: 0.88;
  font-size: 9px;
  left: 43.7vw;
  animation: fall-21 40s -3s ease-in infinite;
}
.snowflake:nth-child(21) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-21 {
  2.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 115.2vw;
  }
}
.snowflake:nth-child(22) {
  opacity: 0.17;
  font-size: 12px;
  left: 6.8vw;
  animation: fall-22 10s -12s ease-in infinite;
}
.snowflake:nth-child(22) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-22 {
  0.6666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 71.2vw;
  }
}
.snowflake:nth-child(23) {
  opacity: 0.76;
  font-size: 3px;
  left: 100.9vw;
  animation: fall-23 50s -28.5s ease-in infinite;
}
.snowflake:nth-child(23) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-23 {
  5.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 73vw;
  }
}
.snowflake:nth-child(24) {
  opacity: 0.12;
  font-size: 12px;
  left: 96.3vw;
  animation: fall-24 40s -10.5s ease-in infinite;
}
.snowflake:nth-child(24) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-24 {
  4% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 95.2vw;
  }
}
.snowflake:nth-child(25) {
  opacity: 0.46;
  font-size: 12px;
  left: 28.9vw;
  animation: fall-25 10s -15s ease-in infinite;
}
.snowflake:nth-child(25) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-25 {
  8.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 101.8vw;
  }
}
.snowflake:nth-child(26) {
  opacity: 0.73;
  font-size: 6px;
  left: 79.2vw;
  animation: fall-26 20s -27s ease-in infinite;
}
.snowflake:nth-child(26) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-26 {
  2.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 17.1vw;
  }
}
.snowflake:nth-child(27) {
  opacity: 0.36;
  font-size: 9px;
  left: 24.7vw;
  animation: fall-27 10s -28.5s ease-in infinite;
}
.snowflake:nth-child(27) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-27 {
  0.6666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 118.9vw;
  }
}
.snowflake:nth-child(28) {
  opacity: 0.46;
  font-size: 15px;
  left: 108.8vw;
  animation: fall-28 10s -10.5s ease-in infinite;
}
.snowflake:nth-child(28) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-28 {
  8% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 67.3vw;
  }
}
.snowflake:nth-child(29) {
  opacity: 0.1;
  font-size: 9px;
  left: 115.3vw;
  animation: fall-29 30s -24s ease-in infinite;
}
.snowflake:nth-child(29) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-29 {
  2.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 76.1vw;
  }
}
.snowflake:nth-child(30) {
  opacity: 0.01;
  font-size: 6px;
  left: 25.5vw;
  animation: fall-30 30s -18s ease-in infinite;
}
.snowflake:nth-child(30) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-30 {
  1% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 36.8vw;
  }
}
.snowflake:nth-child(31) {
  opacity: 0.17;
  font-size: 3px;
  left: 105.3vw;
  animation: fall-31 30s -3s ease-in infinite;
}
.snowflake:nth-child(31) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-31 {
  1.6666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 85.1vw;
  }
}
.snowflake:nth-child(32) {
  opacity: 0.55;
  font-size: 12px;
  left: 32.9vw;
  animation: fall-32 40s -1.5s ease-in infinite;
}
.snowflake:nth-child(32) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-32 {
  3.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 41.9vw;
  }
}
.snowflake:nth-child(33) {
  opacity: 0.57;
  font-size: 9px;
  left: 68.1vw;
  animation: fall-33 50s -18s ease-in infinite;
}
.snowflake:nth-child(33) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-33 {
  2.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 104.5vw;
  }
}
.snowflake:nth-child(34) {
  opacity: 0.76;
  font-size: 3px;
  left: 56.9vw;
  animation: fall-34 50s -22.5s ease-in infinite;
}
.snowflake:nth-child(34) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-34 {
  2.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 66vw;
  }
}
.snowflake:nth-child(35) {
  opacity: 0.46;
  font-size: 15px;
  left: 85vw;
  animation: fall-35 30s -34.5s ease-in infinite;
}
.snowflake:nth-child(35) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-35 {
  0.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 96.2vw;
  }
}
.snowflake:nth-child(36) {
  opacity: 0.87;
  font-size: 3px;
  left: 64.7vw;
  animation: fall-36 10s -9s ease-in infinite;
}
.snowflake:nth-child(36) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-36 {
  3.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 65.1vw;
  }
}
.snowflake:nth-child(37) {
  opacity: 0.73;
  font-size: 3px;
  left: 32.9vw;
  animation: fall-37 50s -21s ease-in infinite;
}
.snowflake:nth-child(37) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-37 {
  6.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 54.4vw;
  }
}
.snowflake:nth-child(38) {
  opacity: 0.51;
  font-size: 6px;
  left: 59.1vw;
  animation: fall-38 20s -16.5s ease-in infinite;
}
.snowflake:nth-child(38) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-38 {
  2.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 61.2vw;
  }
}
.snowflake:nth-child(39) {
  opacity: 0.75;
  font-size: 6px;
  left: 51vw;
  animation: fall-39 10s -15s ease-in infinite;
}
.snowflake:nth-child(39) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-39 {
  3.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 101.3vw;
  }
}
.snowflake:nth-child(40) {
  opacity: 0.19;
  font-size: 12px;
  left: 39.5vw;
  animation: fall-40 20s -28.5s ease-in infinite;
}
.snowflake:nth-child(40) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-40 {
  5.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 85.7vw;
  }
}
.snowflake:nth-child(41) {
  opacity: 0.1;
  font-size: 9px;
  left: 22.7vw;
  animation: fall-41 30s -4.5s ease-in infinite;
}
.snowflake:nth-child(41) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-41 {
  1.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 15.9vw;
  }
}
.snowflake:nth-child(42) {
  opacity: 0.24;
  font-size: 3px;
  left: 33.9vw;
  animation: fall-42 40s -33s ease-in infinite;
}
.snowflake:nth-child(42) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-42 {
  4.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 117.5vw;
  }
}
.snowflake:nth-child(43) {
  opacity: 0.09;
  font-size: 12px;
  left: 30.2vw;
  animation: fall-43 40s -4.5s ease-in infinite;
}
.snowflake:nth-child(43) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-43 {
  4.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 69.1vw;
  }
}
.snowflake:nth-child(44) {
  opacity: 0.53;
  font-size: 9px;
  left: 10.9vw;
  animation: fall-44 20s -33s ease-in infinite;
}
.snowflake:nth-child(44) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-44 {
  2.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 90.1vw;
  }
}
.snowflake:nth-child(45) {
  opacity: 0.46;
  font-size: 15px;
  left: 50.4vw;
  animation: fall-45 40s -28.5s ease-in infinite;
}
.snowflake:nth-child(45) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-45 {
  5.6666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 83.9vw;
  }
}
.snowflake:nth-child(46) {
  opacity: 0.22;
  font-size: 3px;
  left: 38.8vw;
  animation: fall-46 10s -21s ease-in infinite;
}
.snowflake:nth-child(46) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-46 {
  7.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 21.4vw;
  }
}
.snowflake:nth-child(47) {
  opacity: 0.9;
  font-size: 12px;
  left: 13.4vw;
  animation: fall-47 10s -22.5s ease-in infinite;
}
.snowflake:nth-child(47) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-47 {
  5.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 46.5vw;
  }
}
.snowflake:nth-child(48) {
  opacity: 0.29;
  font-size: 15px;
  left: 31.8vw;
  animation: fall-48 30s -21s ease-in infinite;
}
.snowflake:nth-child(48) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-48 {
  5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 118.8vw;
  }
}
.snowflake:nth-child(49) {
  opacity: 0.81;
  font-size: 3px;
  left: 115.2vw;
  animation: fall-49 50s -1.5s ease-in infinite;
}
.snowflake:nth-child(49) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-49 {
  2.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 111.7vw;
  }
}
.snowflake:nth-child(50) {
  opacity: 0.43;
  font-size: 6px;
  left: 87.9vw;
  animation: fall-50 30s -36s ease-in infinite;
}
.snowflake:nth-child(50) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-50 {
  6.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 105.5vw;
  }
}
.snowflake:nth-child(51) {
  opacity: 0.9;
  font-size: 6px;
  left: 6.2vw;
  animation: fall-51 20s -12s ease-in infinite;
}
.snowflake:nth-child(51) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-51 {
  8% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 9.8vw;
  }
}
.snowflake:nth-child(52) {
  opacity: 0.15;
  font-size: 15px;
  left: 64.3vw;
  animation: fall-52 40s -30s ease-in infinite;
}
.snowflake:nth-child(52) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-52 {
  7% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 46.9vw;
  }
}
.snowflake:nth-child(53) {
  opacity: 0.51;
  font-size: 3px;
  left: 3.5vw;
  animation: fall-53 10s -28.5s ease-in infinite;
}
.snowflake:nth-child(53) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-53 {
  4.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 68.4vw;
  }
}
.snowflake:nth-child(54) {
  opacity: 0.24;
  font-size: 15px;
  left: 41.6vw;
  animation: fall-54 30s -10.5s ease-in infinite;
}
.snowflake:nth-child(54) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-54 {
  8% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 6.5vw;
  }
}
.snowflake:nth-child(55) {
  opacity: 0.03;
  font-size: 12px;
  left: 88.2vw;
  animation: fall-55 50s -3s ease-in infinite;
}
.snowflake:nth-child(55) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-55 {
  5.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 93.1vw;
  }
}
.snowflake:nth-child(56) {
  opacity: 0.51;
  font-size: 12px;
  left: 11vw;
  animation: fall-56 50s -4.5s ease-in infinite;
}
.snowflake:nth-child(56) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-56 {
  5.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 34.7vw;
  }
}
.snowflake:nth-child(57) {
  opacity: 0.86;
  font-size: 15px;
  left: 30.2vw;
  animation: fall-57 30s -21s ease-in infinite;
}
.snowflake:nth-child(57) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-57 {
  5.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 43.9vw;
  }
}
.snowflake:nth-child(58) {
  opacity: 0.69;
  font-size: 6px;
  left: 82.8vw;
  animation: fall-58 10s -31.5s ease-in infinite;
}
.snowflake:nth-child(58) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-58 {
  8.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 34.1vw;
  }
}
.snowflake:nth-child(59) {
  opacity: 0.03;
  font-size: 12px;
  left: 94vw;
  animation: fall-59 40s -16.5s ease-in infinite;
}
.snowflake:nth-child(59) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-59 {
  0.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 51.2vw;
  }
}
.snowflake:nth-child(60) {
  opacity: 0.06;
  font-size: 3px;
  left: 57.3vw;
  animation: fall-60 20s -21s ease-in infinite;
}
.snowflake:nth-child(60) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-60 {
  0.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 54.1vw;
  }
}
.snowflake:nth-child(61) {
  opacity: 0.51;
  font-size: 3px;
  left: 98.5vw;
  animation: fall-61 40s -21s ease-in infinite;
}
.snowflake:nth-child(61) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-61 {
  5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 65.5vw;
  }
}
.snowflake:nth-child(62) {
  opacity: 0.63;
  font-size: 6px;
  left: 20.8vw;
  animation: fall-62 30s -34.5s ease-in infinite;
}
.snowflake:nth-child(62) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-62 {
  4.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 32.4vw;
  }
}
.snowflake:nth-child(63) {
  opacity: 0.37;
  font-size: 6px;
  left: 67.2vw;
  animation: fall-63 20s -31.5s ease-in infinite;
}
.snowflake:nth-child(63) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-63 {
  5.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 9.7vw;
  }
}
.snowflake:nth-child(64) {
  opacity: 0.2;
  font-size: 3px;
  left: 12vw;
  animation: fall-64 20s -9s ease-in infinite;
}
.snowflake:nth-child(64) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-64 {
  1% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 15.1vw;
  }
}
.snowflake:nth-child(65) {
  opacity: 0.6;
  font-size: 15px;
  left: 8.8vw;
  animation: fall-65 10s -27s ease-in infinite;
}
.snowflake:nth-child(65) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-65 {
  0.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 98.2vw;
  }
}
.snowflake:nth-child(66) {
  opacity: 0.25;
  font-size: 3px;
  left: 52.2vw;
  animation: fall-66 50s -16.5s ease-in infinite;
}
.snowflake:nth-child(66) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-66 {
  0.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 43.3vw;
  }
}
.snowflake:nth-child(67) {
  opacity: 0.59;
  font-size: 6px;
  left: 111.6vw;
  animation: fall-67 40s -37.5s ease-in infinite;
}
.snowflake:nth-child(67) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-67 {
  2.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 42.4vw;
  }
}
.snowflake:nth-child(68) {
  opacity: 0.84;
  font-size: 12px;
  left: 27.1vw;
  animation: fall-68 40s -4.5s ease-in infinite;
}
.snowflake:nth-child(68) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-68 {
  6% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 87.2vw;
  }
}
.snowflake:nth-child(69) {
  opacity: 0.55;
  font-size: 9px;
  left: 104.4vw;
  animation: fall-69 20s -28.5s ease-in infinite;
}
.snowflake:nth-child(69) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-69 {
  2% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 24.1vw;
  }
}
.snowflake:nth-child(70) {
  opacity: 0.29;
  font-size: 3px;
  left: 115.6vw;
  animation: fall-70 10s -25.5s ease-in infinite;
}
.snowflake:nth-child(70) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-70 {
  0.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 86.1vw;
  }
}
.snowflake:nth-child(71) {
  opacity: 0.44;
  font-size: 9px;
  left: 56.7vw;
  animation: fall-71 40s -6s ease-in infinite;
}
.snowflake:nth-child(71) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-71 {
  2.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 65.4vw;
  }
}
.snowflake:nth-child(72) {
  opacity: 0.73;
  font-size: 6px;
  left: 115.6vw;
  animation: fall-72 30s -3s ease-in infinite;
}
.snowflake:nth-child(72) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-72 {
  1.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 103vw;
  }
}
.snowflake:nth-child(73) {
  opacity: 0.87;
  font-size: 9px;
  left: 107.8vw;
  animation: fall-73 50s -28.5s ease-in infinite;
}
.snowflake:nth-child(73) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-73 {
  6.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 35.8vw;
  }
}
.snowflake:nth-child(74) {
  opacity: 0.6;
  font-size: 12px;
  left: 71.4vw;
  animation: fall-74 20s -6s ease-in infinite;
}
.snowflake:nth-child(74) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-74 {
  2.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 28.8vw;
  }
}
.snowflake:nth-child(75) {
  opacity: 0.08;
  font-size: 3px;
  left: 80.4vw;
  animation: fall-75 40s -19.5s ease-in infinite;
}
.snowflake:nth-child(75) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-75 {
  3% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 10.6vw;
  }
}
.snowflake:nth-child(76) {
  opacity: 0.7;
  font-size: 3px;
  left: 99.6vw;
  animation: fall-76 10s -13.5s ease-in infinite;
}
.snowflake:nth-child(76) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-76 {
  0.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 7.9vw;
  }
}
.snowflake:nth-child(77) {
  opacity: 0.54;
  font-size: 15px;
  left: 3.4vw;
  animation: fall-77 20s -12s ease-in infinite;
}
.snowflake:nth-child(77) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-77 {
  5.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 111.5vw;
  }
}
.snowflake:nth-child(78) {
  opacity: 0.2;
  font-size: 9px;
  left: 32vw;
  animation: fall-78 50s -33s ease-in infinite;
}
.snowflake:nth-child(78) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-78 {
  3.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 39.8vw;
  }
}
.snowflake:nth-child(79) {
  opacity: 0.56;
  font-size: 15px;
  left: 73.4vw;
  animation: fall-79 40s -28.5s ease-in infinite;
}
.snowflake:nth-child(79) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-79 {
  7.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 80.3vw;
  }
}
.snowflake:nth-child(80) {
  opacity: 0.24;
  font-size: 15px;
  left: 70vw;
  animation: fall-80 30s -19.5s ease-in infinite;
}
.snowflake:nth-child(80) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-80 {
  3.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 63.8vw;
  }
}
.snowflake:nth-child(81) {
  opacity: 0.42;
  font-size: 9px;
  left: 26.7vw;
  animation: fall-81 30s -27s ease-in infinite;
}
.snowflake:nth-child(81) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-81 {
  3% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 75vw;
  }
}
.snowflake:nth-child(82) {
  opacity: 0.46;
  font-size: 9px;
  left: 58.4vw;
  animation: fall-82 30s -10.5s ease-in infinite;
}
.snowflake:nth-child(82) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-82 {
  2% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 68.3vw;
  }
}
.snowflake:nth-child(83) {
  opacity: 0.44;
  font-size: 12px;
  left: 99.1vw;
  animation: fall-83 20s -4.5s ease-in infinite;
}
.snowflake:nth-child(83) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-83 {
  5.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 100.8vw;
  }
}
.snowflake:nth-child(84) {
  opacity: 0.53;
  font-size: 9px;
  left: 18.8vw;
  animation: fall-84 10s -16.5s ease-in infinite;
}
.snowflake:nth-child(84) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-84 {
  3.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 105.8vw;
  }
}
.snowflake:nth-child(85) {
  opacity: 0.82;
  font-size: 6px;
  left: 118.6vw;
  animation: fall-85 30s -18s ease-in infinite;
}
.snowflake:nth-child(85) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-85 {
  4.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 43.1vw;
  }
}
.snowflake:nth-child(86) {
  opacity: 0.48;
  font-size: 9px;
  left: 109.3vw;
  animation: fall-86 10s -22.5s ease-in infinite;
}
.snowflake:nth-child(86) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-86 {
  8.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 42.9vw;
  }
}
.snowflake:nth-child(87) {
  opacity: 0.87;
  font-size: 12px;
  left: 72vw;
  animation: fall-87 40s -16.5s ease-in infinite;
}
.snowflake:nth-child(87) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-87 {
  3.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 33.6vw;
  }
}
.snowflake:nth-child(88) {
  opacity: 0.18;
  font-size: 9px;
  left: 7.8vw;
  animation: fall-88 20s -36s ease-in infinite;
}
.snowflake:nth-child(88) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-88 {
  6.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 98.6vw;
  }
}
.snowflake:nth-child(89) {
  opacity: 0.69;
  font-size: 12px;
  left: 106.9vw;
  animation: fall-89 10s -9s ease-in infinite;
}
.snowflake:nth-child(89) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-89 {
  7.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 118vw;
  }
}
.snowflake:nth-child(90) {
  opacity: 0.69;
  font-size: 6px;
  left: 29.6vw;
  animation: fall-90 10s -19.5s ease-in infinite;
}
.snowflake:nth-child(90) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-90 {
  3.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 69.6vw;
  }
}
.snowflake:nth-child(91) {
  opacity: 0.04;
  font-size: 15px;
  left: 83.8vw;
  animation: fall-91 20s -31.5s ease-in infinite;
}
.snowflake:nth-child(91) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-91 {
  4.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 83.7vw;
  }
}
.snowflake:nth-child(92) {
  opacity: 0.43;
  font-size: 15px;
  left: 63.7vw;
  animation: fall-92 10s -7.5s ease-in infinite;
}
.snowflake:nth-child(92) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-92 {
  6.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 90.4vw;
  }
}
.snowflake:nth-child(93) {
  opacity: 0.57;
  font-size: 3px;
  left: 19.7vw;
  animation: fall-93 10s -12s ease-in infinite;
}
.snowflake:nth-child(93) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-93 {
  2.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 41.7vw;
  }
}
.snowflake:nth-child(94) {
  opacity: 0.68;
  font-size: 6px;
  left: 63.9vw;
  animation: fall-94 30s -9s ease-in infinite;
}
.snowflake:nth-child(94) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-94 {
  2.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 68.8vw;
  }
}
.snowflake:nth-child(95) {
  opacity: 0.89;
  font-size: 6px;
  left: 100.2vw;
  animation: fall-95 10s -3s ease-in infinite;
}
.snowflake:nth-child(95) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-95 {
  1.6666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 23.6vw;
  }
}
.snowflake:nth-child(96) {
  opacity: 0.57;
  font-size: 3px;
  left: 38.5vw;
  animation: fall-96 10s -12s ease-in infinite;
}
.snowflake:nth-child(96) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-96 {
  7% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 50.7vw;
  }
}
.snowflake:nth-child(97) {
  opacity: 0.02;
  font-size: 9px;
  left: 15.1vw;
  animation: fall-97 30s -36s ease-in infinite;
}
.snowflake:nth-child(97) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-97 {
  0.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 39.6vw;
  }
}
.snowflake:nth-child(98) {
  opacity: 0.47;
  font-size: 3px;
  left: 65vw;
  animation: fall-98 50s -21s ease-in infinite;
}
.snowflake:nth-child(98) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-98 {
  6.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 27.5vw;
  }
}
.snowflake:nth-child(99) {
  opacity: 0.4;
  font-size: 15px;
  left: 119.9vw;
  animation: fall-99 30s -33s ease-in infinite;
}
.snowflake:nth-child(99) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-99 {
  5.6666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 16vw;
  }
}
.snowflake:nth-child(100) {
  opacity: 0.37;
  font-size: 3px;
  left: 2.3vw;
  animation: fall-100 10s -25.5s ease-in infinite;
}
.snowflake:nth-child(100) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-100 {
  0.6666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 51.7vw;
  }
}
.snowflake:nth-child(101) {
  opacity: 0.21;
  font-size: 12px;
  left: 3.5vw;
  animation: fall-101 50s -30s ease-in infinite;
}
.snowflake:nth-child(101) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-101 {
  7.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 58vw;
  }
}
.snowflake:nth-child(102) {
  opacity: 0.29;
  font-size: 9px;
  left: 36.1vw;
  animation: fall-102 40s -33s ease-in infinite;
}
.snowflake:nth-child(102) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-102 {
  1.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 92.1vw;
  }
}
.snowflake:nth-child(103) {
  opacity: 0.83;
  font-size: 6px;
  left: 110.2vw;
  animation: fall-103 50s -27s ease-in infinite;
}
.snowflake:nth-child(103) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-103 {
  4.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 89.6vw;
  }
}
.snowflake:nth-child(104) {
  opacity: 0.49;
  font-size: 3px;
  left: 74.6vw;
  animation: fall-104 50s -9s ease-in infinite;
}
.snowflake:nth-child(104) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-104 {
  6.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 47.2vw;
  }
}
.snowflake:nth-child(105) {
  opacity: 0.27;
  font-size: 9px;
  left: 85.8vw;
  animation: fall-105 50s -1.5s ease-in infinite;
}
.snowflake:nth-child(105) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-105 {
  4.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 113.3vw;
  }
}
.snowflake:nth-child(106) {
  opacity: 0.67;
  font-size: 3px;
  left: 46.3vw;
  animation: fall-106 40s -16.5s ease-in infinite;
}
.snowflake:nth-child(106) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-106 {
  5.6666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 119vw;
  }
}
.snowflake:nth-child(107) {
  opacity: 0.73;
  font-size: 9px;
  left: 71.2vw;
  animation: fall-107 50s -24s ease-in infinite;
}
.snowflake:nth-child(107) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-107 {
  6.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 39.8vw;
  }
}
.snowflake:nth-child(108) {
  opacity: 0.17;
  font-size: 12px;
  left: 44.6vw;
  animation: fall-108 50s -27s ease-in infinite;
}
.snowflake:nth-child(108) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-108 {
  6.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 29.9vw;
  }
}
.snowflake:nth-child(109) {
  opacity: 0.4;
  font-size: 15px;
  left: 86.9vw;
  animation: fall-109 30s -28.5s ease-in infinite;
}
.snowflake:nth-child(109) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-109 {
  4.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 87.9vw;
  }
}
.snowflake:nth-child(110) {
  opacity: 0.19;
  font-size: 15px;
  left: 87.6vw;
  animation: fall-110 20s -25.5s ease-in infinite;
}
.snowflake:nth-child(110) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-110 {
  6.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 84.4vw;
  }
}
.snowflake:nth-child(111) {
  opacity: 0.63;
  font-size: 12px;
  left: 118.7vw;
  animation: fall-111 30s -33s ease-in infinite;
}
.snowflake:nth-child(111) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-111 {
  1.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 93.5vw;
  }
}
.snowflake:nth-child(112) {
  opacity: 0.1;
  font-size: 3px;
  left: 58.2vw;
  animation: fall-112 20s -19.5s ease-in infinite;
}
.snowflake:nth-child(112) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-112 {
  4% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 20.7vw;
  }
}
.snowflake:nth-child(113) {
  opacity: 0.31;
  font-size: 15px;
  left: 108.4vw;
  animation: fall-113 30s -21s ease-in infinite;
}
.snowflake:nth-child(113) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-113 {
  2.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 45.3vw;
  }
}
.snowflake:nth-child(114) {
  opacity: 0.66;
  font-size: 6px;
  left: 87.9vw;
  animation: fall-114 20s -19.5s ease-in infinite;
}
.snowflake:nth-child(114) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-114 {
  5.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 14.1vw;
  }
}
.snowflake:nth-child(115) {
  opacity: 0.35;
  font-size: 15px;
  left: 30.9vw;
  animation: fall-115 20s -22.5s ease-in infinite;
}
.snowflake:nth-child(115) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-115 {
  0.6666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 14vw;
  }
}
.snowflake:nth-child(116) {
  opacity: 0.84;
  font-size: 9px;
  left: 74.2vw;
  animation: fall-116 30s -31.5s ease-in infinite;
}
.snowflake:nth-child(116) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-116 {
  6.6666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 97.3vw;
  }
}
.snowflake:nth-child(117) {
  opacity: 0.55;
  font-size: 9px;
  left: 41.8vw;
  animation: fall-117 30s -3s ease-in infinite;
}
.snowflake:nth-child(117) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-117 {
  0.6666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 90.9vw;
  }
}
.snowflake:nth-child(118) {
  opacity: 0.47;
  font-size: 3px;
  left: 86.5vw;
  animation: fall-118 10s -12s ease-in infinite;
}
.snowflake:nth-child(118) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-118 {
  2.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 97.8vw;
  }
}
.snowflake:nth-child(119) {
  opacity: 0.89;
  font-size: 9px;
  left: 33vw;
  animation: fall-119 20s -22.5s ease-in infinite;
}
.snowflake:nth-child(119) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-119 {
  2.6666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 93.2vw;
  }
}
.snowflake:nth-child(120) {
  opacity: 0.43;
  font-size: 9px;
  left: 117.8vw;
  animation: fall-120 30s -22.5s ease-in infinite;
}
.snowflake:nth-child(120) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-120 {
  7% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 107.1vw;
  }
}
.snowflake:nth-child(121) {
  opacity: 0.23;
  font-size: 3px;
  left: 100.6vw;
  animation: fall-121 20s -9s ease-in infinite;
}
.snowflake:nth-child(121) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-121 {
  7.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 82.1vw;
  }
}
.snowflake:nth-child(122) {
  opacity: 0.18;
  font-size: 9px;
  left: 47.5vw;
  animation: fall-122 30s -15s ease-in infinite;
}
.snowflake:nth-child(122) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-122 {
  3% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 98.4vw;
  }
}
.snowflake:nth-child(123) {
  opacity: 0.29;
  font-size: 12px;
  left: 22.4vw;
  animation: fall-123 10s -9s ease-in infinite;
}
.snowflake:nth-child(123) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-123 {
  6.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 36.1vw;
  }
}
.snowflake:nth-child(124) {
  opacity: 0.04;
  font-size: 3px;
  left: 5vw;
  animation: fall-124 40s -3s ease-in infinite;
}
.snowflake:nth-child(124) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-124 {
  4.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 36.7vw;
  }
}
.snowflake:nth-child(125) {
  opacity: 0.42;
  font-size: 9px;
  left: 78.6vw;
  animation: fall-125 10s -10.5s ease-in infinite;
}
.snowflake:nth-child(125) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-125 {
  3.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 29.8vw;
  }
}
.snowflake:nth-child(126) {
  opacity: 0.44;
  font-size: 3px;
  left: 77.1vw;
  animation: fall-126 20s -28.5s ease-in infinite;
}
.snowflake:nth-child(126) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-126 {
  4.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 72.9vw;
  }
}
.snowflake:nth-child(127) {
  opacity: 0.56;
  font-size: 15px;
  left: 17.1vw;
  animation: fall-127 40s -9s ease-in infinite;
}
.snowflake:nth-child(127) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-127 {
  1% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 105vw;
  }
}
.snowflake:nth-child(128) {
  opacity: 0.21;
  font-size: 15px;
  left: 104.2vw;
  animation: fall-128 10s -33s ease-in infinite;
}
.snowflake:nth-child(128) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-128 {
  5.6666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 66vw;
  }
}
.snowflake:nth-child(129) {
  opacity: 0.81;
  font-size: 3px;
  left: 53.1vw;
  animation: fall-129 20s -10.5s ease-in infinite;
}
.snowflake:nth-child(129) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-129 {
  0.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 90.5vw;
  }
}
.snowflake:nth-child(130) {
  opacity: 0.09;
  font-size: 15px;
  left: 34.3vw;
  animation: fall-130 50s -15s ease-in infinite;
}
.snowflake:nth-child(130) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-130 {
  8% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 115.3vw;
  }
}
.snowflake:nth-child(131) {
  opacity: 0.9;
  font-size: 3px;
  left: 95.4vw;
  animation: fall-131 30s -37.5s ease-in infinite;
}
.snowflake:nth-child(131) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-131 {
  1.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 93.7vw;
  }
}
.snowflake:nth-child(132) {
  opacity: 0.69;
  font-size: 9px;
  left: 59.3vw;
  animation: fall-132 20s -33s ease-in infinite;
}
.snowflake:nth-child(132) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-132 {
  1% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 81.2vw;
  }
}
.snowflake:nth-child(133) {
  opacity: 0.39;
  font-size: 9px;
  left: 13.7vw;
  animation: fall-133 50s -21s ease-in infinite;
}
.snowflake:nth-child(133) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-133 {
  4.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 18.6vw;
  }
}
.snowflake:nth-child(134) {
  opacity: 0.58;
  font-size: 15px;
  left: 115.6vw;
  animation: fall-134 50s -34.5s ease-in infinite;
}
.snowflake:nth-child(134) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-134 {
  4.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 53vw;
  }
}
.snowflake:nth-child(135) {
  opacity: 0.33;
  font-size: 6px;
  left: 116.7vw;
  animation: fall-135 30s -10.5s ease-in infinite;
}
.snowflake:nth-child(135) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-135 {
  2.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 5.6vw;
  }
}
.snowflake:nth-child(136) {
  opacity: 0.73;
  font-size: 3px;
  left: 56.8vw;
  animation: fall-136 50s -4.5s ease-in infinite;
}
.snowflake:nth-child(136) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-136 {
  3.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 37.3vw;
  }
}
.snowflake:nth-child(137) {
  opacity: 0.19;
  font-size: 3px;
  left: 2.6vw;
  animation: fall-137 30s -33s ease-in infinite;
}
.snowflake:nth-child(137) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-137 {
  6.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 36.1vw;
  }
}
.snowflake:nth-child(138) {
  opacity: 0.66;
  font-size: 3px;
  left: 24.3vw;
  animation: fall-138 30s -36s ease-in infinite;
}
.snowflake:nth-child(138) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-138 {
  3.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 110.5vw;
  }
}
.snowflake:nth-child(139) {
  opacity: 0.64;
  font-size: 12px;
  left: 33.7vw;
  animation: fall-139 20s -37.5s ease-in infinite;
}
.snowflake:nth-child(139) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-139 {
  0.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 2.4vw;
  }
}
.snowflake:nth-child(140) {
  opacity: 0.73;
  font-size: 9px;
  left: 64.1vw;
  animation: fall-140 10s -10.5s ease-in infinite;
}
.snowflake:nth-child(140) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-140 {
  4.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 13.4vw;
  }
}
.snowflake:nth-child(141) {
  opacity: 0.12;
  font-size: 3px;
  left: 66.2vw;
  animation: fall-141 50s -30s ease-in infinite;
}
.snowflake:nth-child(141) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-141 {
  2.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 82.8vw;
  }
}
.snowflake:nth-child(142) {
  opacity: 0.47;
  font-size: 6px;
  left: 47vw;
  animation: fall-142 30s -34.5s ease-in infinite;
}
.snowflake:nth-child(142) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-142 {
  0.6666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 20.7vw;
  }
}
.snowflake:nth-child(143) {
  opacity: 0.58;
  font-size: 3px;
  left: 88.1vw;
  animation: fall-143 50s -18s ease-in infinite;
}
.snowflake:nth-child(143) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-143 {
  4.6666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 45.9vw;
  }
}
.snowflake:nth-child(144) {
  opacity: 0.11;
  font-size: 3px;
  left: 14.1vw;
  animation: fall-144 10s -18s ease-in infinite;
}
.snowflake:nth-child(144) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-144 {
  2% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 106vw;
  }
}
.snowflake:nth-child(145) {
  opacity: 0.9;
  font-size: 6px;
  left: 12.8vw;
  animation: fall-145 20s -21s ease-in infinite;
}
.snowflake:nth-child(145) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-145 {
  2.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 38.9vw;
  }
}
.snowflake:nth-child(146) {
  opacity: 0.27;
  font-size: 9px;
  left: 99.5vw;
  animation: fall-146 30s -34.5s ease-in infinite;
}
.snowflake:nth-child(146) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-146 {
  5.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 28.7vw;
  }
}
.snowflake:nth-child(147) {
  opacity: 0.11;
  font-size: 3px;
  left: 5.4vw;
  animation: fall-147 40s -25.5s ease-in infinite;
}
.snowflake:nth-child(147) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-147 {
  1.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 76.4vw;
  }
}
.snowflake:nth-child(148) {
  opacity: 0.31;
  font-size: 15px;
  left: 37.9vw;
  animation: fall-148 50s -30s ease-in infinite;
}
.snowflake:nth-child(148) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-148 {
  1.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 47vw;
  }
}
.snowflake:nth-child(149) {
  opacity: 0.86;
  font-size: 3px;
  left: 64.2vw;
  animation: fall-149 20s -34.5s ease-in infinite;
}
.snowflake:nth-child(149) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-149 {
  4.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 54.6vw;
  }
}
.snowflake:nth-child(150) {
  opacity: 0.4;
  font-size: 6px;
  left: 11.3vw;
  animation: fall-150 20s -10.5s ease-in infinite;
}
.snowflake:nth-child(150) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-150 {
  3.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 16.3vw;
  }
}
.snowflake:nth-child(151) {
  opacity: 0.88;
  font-size: 9px;
  left: 40.8vw;
  animation: fall-151 40s -1.5s ease-in infinite;
}
.snowflake:nth-child(151) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-151 {
  6.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 79.4vw;
  }
}
.snowflake:nth-child(152) {
  opacity: 0.71;
  font-size: 9px;
  left: 85.6vw;
  animation: fall-152 40s -33s ease-in infinite;
}
.snowflake:nth-child(152) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-152 {
  0.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 60.8vw;
  }
}
.snowflake:nth-child(153) {
  opacity: 0.09;
  font-size: 15px;
  left: 60.5vw;
  animation: fall-153 30s -6s ease-in infinite;
}
.snowflake:nth-child(153) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-153 {
  3.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 105.5vw;
  }
}
.snowflake:nth-child(154) {
  opacity: 0.58;
  font-size: 3px;
  left: 115.4vw;
  animation: fall-154 50s -15s ease-in infinite;
}
.snowflake:nth-child(154) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-154 {
  8.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 104.9vw;
  }
}
.snowflake:nth-child(155) {
  opacity: 0.65;
  font-size: 9px;
  left: 40.5vw;
  animation: fall-155 30s -28.5s ease-in infinite;
}
.snowflake:nth-child(155) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-155 {
  7.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 15.7vw;
  }
}
.snowflake:nth-child(156) {
  opacity: 0.9;
  font-size: 15px;
  left: 116.2vw;
  animation: fall-156 40s -25.5s ease-in infinite;
}
.snowflake:nth-child(156) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-156 {
  4% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 26.8vw;
  }
}
.snowflake:nth-child(157) {
  opacity: 0.17;
  font-size: 3px;
  left: 39vw;
  animation: fall-157 20s -4.5s ease-in infinite;
}
.snowflake:nth-child(157) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-157 {
  6.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 53.5vw;
  }
}
.snowflake:nth-child(158) {
  opacity: 0.61;
  font-size: 9px;
  left: 51.9vw;
  animation: fall-158 30s -31.5s ease-in infinite;
}
.snowflake:nth-child(158) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-158 {
  7.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 42.6vw;
  }
}
.snowflake:nth-child(159) {
  opacity: 0.62;
  font-size: 3px;
  left: 48.5vw;
  animation: fall-159 40s -3s ease-in infinite;
}
.snowflake:nth-child(159) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-159 {
  2.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 55.6vw;
  }
}
.snowflake:nth-child(160) {
  opacity: 0.26;
  font-size: 15px;
  left: 59vw;
  animation: fall-160 10s -9s ease-in infinite;
}
.snowflake:nth-child(160) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-160 {
  8.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 44.8vw;
  }
}
.snowflake:nth-child(161) {
  opacity: 0.62;
  font-size: 6px;
  left: 99.7vw;
  animation: fall-161 30s -34.5s ease-in infinite;
}
.snowflake:nth-child(161) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-161 {
  1.6666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 110vw;
  }
}
.snowflake:nth-child(162) {
  opacity: 0.9;
  font-size: 6px;
  left: 78.8vw;
  animation: fall-162 50s -36s ease-in infinite;
}
.snowflake:nth-child(162) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-162 {
  1.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 63.7vw;
  }
}
.snowflake:nth-child(163) {
  opacity: 0.34;
  font-size: 15px;
  left: 35.6vw;
  animation: fall-163 40s -24s ease-in infinite;
}
.snowflake:nth-child(163) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-163 {
  2.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 39.1vw;
  }
}
.snowflake:nth-child(164) {
  opacity: 0.3;
  font-size: 12px;
  left: 80.7vw;
  animation: fall-164 20s -33s ease-in infinite;
}
.snowflake:nth-child(164) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-164 {
  3.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 84.8vw;
  }
}
.snowflake:nth-child(165) {
  opacity: 0.82;
  font-size: 12px;
  left: 70.8vw;
  animation: fall-165 10s -37.5s ease-in infinite;
}
.snowflake:nth-child(165) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-165 {
  1.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 72.6vw;
  }
}
.snowflake:nth-child(166) {
  opacity: 0.32;
  font-size: 9px;
  left: 103vw;
  animation: fall-166 20s -30s ease-in infinite;
}
.snowflake:nth-child(166) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-166 {
  2.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 29.9vw;
  }
}
.snowflake:nth-child(167) {
  opacity: 0.84;
  font-size: 6px;
  left: 91.8vw;
  animation: fall-167 30s -27s ease-in infinite;
}
.snowflake:nth-child(167) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-167 {
  5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 118.5vw;
  }
}
.snowflake:nth-child(168) {
  opacity: 0.14;
  font-size: 3px;
  left: 82.4vw;
  animation: fall-168 40s -30s ease-in infinite;
}
.snowflake:nth-child(168) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-168 {
  2% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 75.3vw;
  }
}
.snowflake:nth-child(169) {
  opacity: 0.27;
  font-size: 9px;
  left: 87.5vw;
  animation: fall-169 40s -34.5s ease-in infinite;
}
.snowflake:nth-child(169) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-169 {
  4.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 111vw;
  }
}
.snowflake:nth-child(170) {
  opacity: 0.48;
  font-size: 3px;
  left: 40.9vw;
  animation: fall-170 10s -15s ease-in infinite;
}
.snowflake:nth-child(170) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-170 {
  3.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 42.2vw;
  }
}
.snowflake:nth-child(171) {
  opacity: 0.53;
  font-size: 12px;
  left: 79vw;
  animation: fall-171 50s -22.5s ease-in infinite;
}
.snowflake:nth-child(171) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-171 {
  0.6666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 95.9vw;
  }
}
.snowflake:nth-child(172) {
  opacity: 0.82;
  font-size: 9px;
  left: 72.2vw;
  animation: fall-172 40s -30s ease-in infinite;
}
.snowflake:nth-child(172) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-172 {
  1.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 13.1vw;
  }
}
.snowflake:nth-child(173) {
  opacity: 0.49;
  font-size: 3px;
  left: 50.3vw;
  animation: fall-173 50s -27s ease-in infinite;
}
.snowflake:nth-child(173) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-173 {
  1% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 53vw;
  }
}
.snowflake:nth-child(174) {
  opacity: 0.09;
  font-size: 12px;
  left: 19.6vw;
  animation: fall-174 30s -10.5s ease-in infinite;
}
.snowflake:nth-child(174) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-174 {
  4.6666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 25.3vw;
  }
}
.snowflake:nth-child(175) {
  opacity: 0.7;
  font-size: 9px;
  left: 71vw;
  animation: fall-175 40s -37.5s ease-in infinite;
}
.snowflake:nth-child(175) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-175 {
  2.6666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 70.6vw;
  }
}
.snowflake:nth-child(176) {
  opacity: 0.19;
  font-size: 12px;
  left: 80.7vw;
  animation: fall-176 30s -7.5s ease-in infinite;
}
.snowflake:nth-child(176) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-176 {
  4.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 50.1vw;
  }
}
.snowflake:nth-child(177) {
  opacity: 0.64;
  font-size: 3px;
  left: 117.6vw;
  animation: fall-177 30s -3s ease-in infinite;
}
.snowflake:nth-child(177) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-177 {
  7.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 7.3vw;
  }
}
.snowflake:nth-child(178) {
  opacity: 0.89;
  font-size: 15px;
  left: 7vw;
  animation: fall-178 30s -9s ease-in infinite;
}
.snowflake:nth-child(178) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-178 {
  4.6666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 75.7vw;
  }
}
.snowflake:nth-child(179) {
  opacity: 0.31;
  font-size: 12px;
  left: 90.7vw;
  animation: fall-179 20s -24s ease-in infinite;
}
.snowflake:nth-child(179) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-179 {
  7.1666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 103.2vw;
  }
}
.snowflake:nth-child(180) {
  opacity: 0.32;
  font-size: 3px;
  left: 73vw;
  animation: fall-180 50s -37.5s ease-in infinite;
}
.snowflake:nth-child(180) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-180 {
  3.6666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 83.9vw;
  }
}
.snowflake:nth-child(181) {
  opacity: 0.78;
  font-size: 6px;
  left: 100.6vw;
  animation: fall-181 40s -25.5s ease-in infinite;
}
.snowflake:nth-child(181) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-181 {
  6.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 103.6vw;
  }
}
.snowflake:nth-child(182) {
  opacity: 0.73;
  font-size: 15px;
  left: 24.1vw;
  animation: fall-182 50s -33s ease-in infinite;
}
.snowflake:nth-child(182) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-182 {
  7.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 23.2vw;
  }
}
.snowflake:nth-child(183) {
  opacity: 0.15;
  font-size: 12px;
  left: 28.4vw;
  animation: fall-183 40s -22.5s ease-in infinite;
}
.snowflake:nth-child(183) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-183 {
  2.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 37.5vw;
  }
}
.snowflake:nth-child(184) {
  opacity: 0.27;
  font-size: 9px;
  left: 115.4vw;
  animation: fall-184 40s -12s ease-in infinite;
}
.snowflake:nth-child(184) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-184 {
  5.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 53.5vw;
  }
}
.snowflake:nth-child(185) {
  opacity: 0.57;
  font-size: 6px;
  left: 104.6vw;
  animation: fall-185 10s -12s ease-in infinite;
}
.snowflake:nth-child(185) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-185 {
  5.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 20.4vw;
  }
}
.snowflake:nth-child(186) {
  opacity: 0.73;
  font-size: 15px;
  left: 15.9vw;
  animation: fall-186 10s -4.5s ease-in infinite;
}
.snowflake:nth-child(186) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-186 {
  1.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 55.9vw;
  }
}
.snowflake:nth-child(187) {
  opacity: 0.25;
  font-size: 6px;
  left: 36.3vw;
  animation: fall-187 10s -3s ease-in infinite;
}
.snowflake:nth-child(187) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-187 {
  1.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 56.8vw;
  }
}
.snowflake:nth-child(188) {
  opacity: 0.86;
  font-size: 15px;
  left: 37vw;
  animation: fall-188 10s -6s ease-in infinite;
}
.snowflake:nth-child(188) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-188 {
  6.6666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 11.7vw;
  }
}
.snowflake:nth-child(189) {
  opacity: 0.51;
  font-size: 15px;
  left: 43.7vw;
  animation: fall-189 10s -3s ease-in infinite;
}
.snowflake:nth-child(189) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-189 {
  3% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 97.6vw;
  }
}
.snowflake:nth-child(190) {
  opacity: 0.35;
  font-size: 6px;
  left: 91vw;
  animation: fall-190 10s -16.5s ease-in infinite;
}
.snowflake:nth-child(190) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-190 {
  4.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 21.1vw;
  }
}
.snowflake:nth-child(191) {
  opacity: 0.32;
  font-size: 9px;
  left: 32vw;
  animation: fall-191 50s -13.5s ease-in infinite;
}
.snowflake:nth-child(191) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-191 {
  1.3333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 2.1vw;
  }
}
.snowflake:nth-child(192) {
  opacity: 0.81;
  font-size: 15px;
  left: 77.3vw;
  animation: fall-192 30s -37.5s ease-in infinite;
}
.snowflake:nth-child(192) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-192 {
  7.6666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 94.4vw;
  }
}
.snowflake:nth-child(193) {
  opacity: 0.52;
  font-size: 6px;
  left: 6vw;
  animation: fall-193 10s -4.5s ease-in infinite;
}
.snowflake:nth-child(193) span {
  animation: spin 3s linear 0s infinite;
  filter: drop-shadow(0 0 1px #fff);
}
@keyframes fall-193 {
  2.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 69.9vw;
  }
}
.snowflake:nth-child(194) {
  opacity: 0.56;
  font-size: 15px;
  left: 118.4vw;
  animation: fall-194 20s -4.5s ease-in infinite;
}
.snowflake:nth-child(194) span {
  animation: spin 9s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-194 {
  4.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 21.1vw;
  }
}
.snowflake:nth-child(195) {
  opacity: 0.22;
  font-size: 3px;
  left: 101.2vw;
  animation: fall-195 50s -16.5s ease-in infinite;
}
.snowflake:nth-child(195) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-195 {
  1% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 70.6vw;
  }
}
.snowflake:nth-child(196) {
  opacity: 0.85;
  font-size: 9px;
  left: 104.3vw;
  animation: fall-196 20s -33s ease-in infinite;
}
.snowflake:nth-child(196) span {
  animation: spin 12s linear 0s infinite;
  filter: drop-shadow(0 0 2px #fff);
}
@keyframes fall-196 {
  5.5% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 89.1vw;
  }
}
.snowflake:nth-child(197) {
  opacity: 0.79;
  font-size: 3px;
  left: 28.5vw;
  animation: fall-197 20s -18s ease-in infinite;
}
.snowflake:nth-child(197) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 5px #fff);
}
@keyframes fall-197 {
  3.6666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 41vw;
  }
}
.snowflake:nth-child(198) {
  opacity: 0.74;
  font-size: 9px;
  left: 108.4vw;
  animation: fall-198 40s -25.5s ease-in infinite;
}
.snowflake:nth-child(198) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-198 {
  7.6666666667% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 76.1vw;
  }
}
.snowflake:nth-child(199) {
  opacity: 0.65;
  font-size: 12px;
  left: 109.4vw;
  animation: fall-199 40s -19.5s ease-in infinite;
}
.snowflake:nth-child(199) span {
  animation: spin 15s linear 0s infinite;
  filter: drop-shadow(0 0 4px #fff);
}
@keyframes fall-199 {
  0.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 67.6vw;
  }
}
.snowflake:nth-child(200) {
  opacity: 0.84;
  font-size: 12px;
  left: 6.8vw;
  animation: fall-200 30s -13.5s ease-in infinite;
}
.snowflake:nth-child(200) span {
  animation: spin 6s linear 0s infinite;
  filter: drop-shadow(0 0 3px #fff);
}
@keyframes fall-200 {
  4.8333333333% {
    transform: rotate(90deg) translateX(0);
  }
  to {
    transform: rotate(90deg) translateX(calc(100vh + 5vmin));
    left: 88.1vw;
  }
}
.snowflake span {
  display: block;
  color: #fff;
}
.snowflake span:before {
  content: "❄";
}
.snowflake:nth-child(4n+2) span:before {
  content: "❅";
}
.snowflake:nth-child(4n+3) span:before {
  content: "❇";
}
.snowflake:nth-child(4n+4) span:before {
  content: "❋";
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>